require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/FlowLogger/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var session, message;

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.has = subs.has || (subId => !!subs.map[subId]);subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}


  session = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession'));
  if (session) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Logging', ({ [`sessionId`]: session }));
  } else {
    session = mathRandomInt(10000, 99999);
    ___arguments.context.pageData['sessionId'] = session;
    console.log(session);
    console.log(JSON.stringify(session));

    ;(function(selector) {
      const callback = async message => {
          console.log('got message');
      console.log(message);
      getRTSubscriptions().remove('firstMessageListener');
      ___arguments.context.appData['firstMessage'] = (getObjectProperty(message, 'message'));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Logging', ({ [`sessionId`]: session }));

      };

      const channel = getChannelInstance((JSON.stringify(session)));

      const options = [callback];

      if (selector) {
         options.unshift(selector);
      }

      channel.addMessageListener.apply(channel, options);

      getRTSubscriptions().add('firstMessageListener', () => channel.removeMessageListener.apply(channel, options));
    })('');
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Logging/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var message, raw_message, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function prep_message(raw_message) {
  return [((new Date()).toLocaleTimeString()),': ',raw_message].join('')
}


  ___arguments.context.pageData['fontsize'] = 14;
  if (!((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession')) && !(getObjectProperty(___arguments.context.pageData, 'sessionId'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('FlowLogger', undefined);
    return ;
  }
  if (getObjectProperty(___arguments.context.pageData, 'sessionId')) {
    localStorage.setItem('activeSession', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'sessionId'))));
  } else {
    ___arguments.context.pageData['sessionId'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession'));
  }
  ___arguments.context.pageData['messages'] = ((getObjectProperty(___arguments.context.appData, 'firstMessage')) ? [(getObjectProperty(___arguments.context.appData, 'firstMessage'))] : []);

  ;(function(selector) {
    const callback = async message => {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'messages')), (await prep_message((getObjectProperty(message, 'message')))));

    };

    const channel = getChannelInstance((String(getObjectProperty(___arguments.context.pageData, 'sessionId')) + String('')));

    const options = [callback];

    if (selector) {
       options.unshift(selector);
    }

    channel.addMessageListener.apply(channel, options);


  })('');

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Logging/components/09c3940a067b2745788614295eb25893/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return ___arguments.context.dataModel

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`font-size`]: (getObjectProperty(___arguments.context.pageData, 'fontsize')) })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Logging/components/313baff3d4234ced856639d5fe8ce496/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      localStorage.removeItem('activeSession');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('FlowLogger', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/ffed7bdc14b7f85da6c2f15c36318493/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['messages'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/27fddc838dda029725b3c52ad3d8e56c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fontsize'] = ((getObjectProperty(___arguments.context.pageData, 'fontsize')) + 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/4488ddcd27fff854fc4c283f3d265bb3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fontsize'] = ((getObjectProperty(___arguments.context.pageData, 'fontsize')) > 5 ? (getObjectProperty(___arguments.context.pageData, 'fontsize')) - 1 : 5);

  },
  /* handler:onClick */
  /* content */
}))

