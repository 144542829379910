require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/FlowLogger/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var session, message;

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.has = subs.has || (subId => !!subs.map[subId]);subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}


  session = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession'));
  if (session) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Logging', ({ [`sessionId`]: session }));
  } else {
    session = mathRandomInt(10000, 99999);
    ___arguments.context.pageData['sessionId'] = session;

    ;(function(selector) {
      const callback = async message => {
          getRTSubscriptions().remove('firstMessageListener');
      ___arguments.context.appData['firstMessage'] = (getObjectProperty(message, 'message'));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Logging', ({ [`sessionId`]: session }));

      };

      const channel = getChannelInstance((JSON.stringify(session)));

      const options = [callback];

      if (selector) {
         options.unshift(selector);
      }

      channel.addMessageListener.apply(channel, options);

      getRTSubscriptions().add('firstMessageListener', () => channel.removeMessageListener.apply(channel, options));
    })('');
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Logging/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var formattedMessage, message, prepMessage, raw_message, objMessage, textToInput, currentFormattedMessage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function prep_message(raw_message) {
  try {
    objMessage = (JSON.parse(raw_message));
    if ((objMessage !== null && !Array.isArray(objMessage) && (typeof objMessage === 'object')) || (Array.isArray(objMessage))) {
      prepMessage = (await (async function(inputMessage) {
      	function createJsonMarkup(data) {
      	  if (data === null) {
      	    return '<span class="null">null</span>';
      	  }

      	  const type = typeof data;
      	  if (type === 'object') {
      	    if (Array.isArray(data)) {
      	      return renderArray(data);
      	    } else {
      	      return renderObject(data);
      	    }
      	  } else if (type === 'string') {
      	    return `<span class="string">"${data}"</span>`;
      	  } else if (type === 'number') {
      	    return `<span class="number">${data}</span>`;
      	  } else if (type === 'boolean') {
      	    return `<span class="boolean">${data}</span>`;
      	  }

      	  return data;
      	}

      	function renderArray(arr) {
      	  let output = "<span class='expandable'>[</span>";
      	  output += "<ul>";
      	  const lastIndex = arr.length -1;
      	  arr.forEach((item, index) => {
      	    output += `<li>${createJsonMarkup(item)}${index == lastIndex?'': ','}</li>`;
      	  });

      	  output += "</ul>";
      	  output += "<span class='not-expandable'>]</span>";

      	  return output;
      	}

      	function renderObject(obj) {
      	  let output = "<span class='expandable'>{</span>";
      	  output += "<ul>";
      	  const objKeys = Object.keys(obj);
      	  const lastObjKey = objKeys[objKeys.length-1];
      	  for (let key in obj) {
      	    if (obj.hasOwnProperty(key)) {
      	      output += `<li><span class="key">${key}</span>: ${createJsonMarkup(obj[key])}${key == lastObjKey?'': ','}</li>`;
      	    }
      	  }

      	  output += "</ul>";
      	  output += "<span class='not-expandable'>}</span>";

      	  return output;
      	}

      	if (Array.isArray(inputMessage)) {
      	   return `<div class='json-container'>${renderArray(inputMessage)}</div>`;
      	} else {
      	   return `<div class='json-container'>${renderObject(inputMessage)}</div>`;
      	}
      })(objMessage));
    } else {
      prepMessage = raw_message;
    }

  } catch (error) {
    prepMessage = raw_message;

  }
  return prepMessage
}

/**
 * Describe this function...
 */
async function startJsonListeners(currentFormattedMessage) {
  if (formattedMessage.indexOf('<span class=\'expandable\'>') !== -1) {
    await (async function() {
    	try {
    	  containers.forEach(el => {
    	    el.removeEventListener('click', jconControl);
    	  });
    	} catch {}

    	containers = document.querySelectorAll('.json-container');

    	jconControl = function (event) {
    	  const target = event.target;
    	  if (target.classList.contains('expandable')) {
    	    const nextElement = target.nextElementSibling;
    	    if (nextElement && nextElement.tagName === 'UL') {
    	      nextElement.classList.toggle('collapsed');
    	      target.textContent = nextElement.classList.contains('collapsed') ? (target.textContent === '[' ? '[+': '{+'): (target.textContent === '[+' ? '[': '{');
    	    }
    	  }
    	}

    	containers.forEach(el => {
    	  el.addEventListener('click', jconControl);
    	});
    })();
  }
}


  ___arguments.context.pageData['fontsize'] = 14;
  if (!((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession')) && !(getObjectProperty(___arguments.context.pageData, 'sessionId'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('FlowLogger', undefined);
    return ;
  }
  if (getObjectProperty(___arguments.context.pageData, 'sessionId')) {
    localStorage.setItem('activeSession', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'sessionId'))));
  } else {
    ___arguments.context.pageData['sessionId'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('activeSession'));
  }
  if (getObjectProperty(___arguments.context.appData, 'firstMessage')) {
    formattedMessage = await prep_message((getObjectProperty(___arguments.context.appData, 'firstMessage')));
    ___arguments.context.pageData['messages'] = [({ [`messageDate`]: String((new Date()).toLocaleTimeString()) + String(': '),[`message`]: formattedMessage })];
    await startJsonListeners(formattedMessage);
    await new Promise(r => setTimeout(r, 50 || 0));
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('93e67c98ab1ea17dedefa3173d35f5ba')));
  } else {
    ___arguments.context.pageData['messages'] = [];
  }

  ;(function(selector) {
    const callback = async message => {
        formattedMessage = await prep_message((getObjectProperty(message, 'message')));
    addItemToList((getObjectProperty(___arguments.context.pageData, 'messages')), ({ [`messageDate`]: String((new Date()).toLocaleTimeString()) + String(': '),[`message`]: formattedMessage }));
    await startJsonListeners(formattedMessage);
    if (!(getObjectProperty(___arguments.context.pageData, 'scrollBottom')) || (getObjectProperty(___arguments.context.pageData, 'scrollBottom')) <= 20) {
      await new Promise(r => setTimeout(r, 50 || 0));
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('93e67c98ab1ea17dedefa3173d35f5ba')));
    } else {
      if (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7ef8ce12c6d578dd30c7af0b1b093a6b')))) != (await (async function(el) {
      	return el.scrollHeight;
      })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7ef8ce12c6d578dd30c7af0b1b093a6b')), 'el'))))) {
        ___arguments.context.pageData['isScrollDownBtnVisible'] = true;
      }
    }

    };

    const channel = getChannelInstance((String(getObjectProperty(___arguments.context.pageData, 'sessionId')) + String('')));

    const options = [callback];

    if (selector) {
       options.unshift(selector);
    }

    channel.addMessageListener.apply(channel, options);


  })('');

  },
  /* handler:onBeforeEnter */
  /* handler:onLeave */
  async ['onLeave'](___arguments) {
      await (async function() {
  	try {
  	  containers.forEach(el => {
  	    el.removeEventListener('click', jconControl);
  	  });
  	} catch {}
  })();

  },
  /* handler:onLeave */
  /* content */
}))

define('./pages/Logging/components/09c3940a067b2745788614295eb25893/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`font-size`]: (getObjectProperty(___arguments.context.pageData, 'fontsize')) })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Logging/components/313baff3d4234ced856639d5fe8ce496/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      localStorage.removeItem('activeSession');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('FlowLogger', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/ffed7bdc14b7f85da6c2f15c36318493/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['messages'] = [];
  ___arguments.context.pageData['isScrollDownBtnVisible'] = false;
  ___arguments.context.pageData['scrollBottom'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/27fddc838dda029725b3c52ad3d8e56c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fontsize'] = ((getObjectProperty(___arguments.context.pageData, 'fontsize')) + 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/4488ddcd27fff854fc4c283f3d265bb3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['fontsize'] = ((getObjectProperty(___arguments.context.pageData, 'fontsize')) > 5 ? (getObjectProperty(___arguments.context.pageData, 'fontsize')) - 1 : 5);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Logging/components/70a2cd7d6cb24b9b6c66359bbf5a28df/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`font-size`]: (getObjectProperty(___arguments.context.pageData, 'fontsize')) })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Logging/components/7ef8ce12c6d578dd30c7af0b1b093a6b/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
    function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('runScrollLogic');

  ;(function() {
    const callback = async () => {
        ___arguments.context.pageData['scrollBottom'] = ___arguments.scrollBottom;
    if (___arguments.scrollBottom <= 20) {
      ___arguments.context.pageData['isScrollDownBtnVisible'] = false;
    }

    };

    const timerId = 'runScrollLogic';
    const timerDelay = 100;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/Logging/components/e471e79eef7c89a3113b98d1288ec937/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('93e67c98ab1ea17dedefa3173d35f5ba')));
  ___arguments.context.pageData['isScrollDownBtnVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

